import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Card, Descriptions, message, Modal, Row, Switch } from "antd";
import { useMsal } from "@azure/msal-react";
import {
  disableAthena,
  enableAthena,
  getAthenaObject,
  getJdbcString,
  toggleCrawler,
} from "http/userApi";
import { IPond } from "pages/Ponds/types";
import { SegmentedValue } from "antd/lib/segmented";
import {
  CaretRightOutlined,
  CopyOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { initialCrawler } from "const";
import { bucketUrl } from "http/index";
import { IAthenaObject } from "./types";
import useFetch from "hooks/useFetch";
import { handleCopyText } from "helpers";
import { useLogin } from "hooks/useLogin";

import Button from "common/Button";
import Spinner from "common/Spinner";
import GetAccess from "./GetAccess";
import About from "./About";

import "./style.scss";

const Athena = ({
  bucket,
  onFetchBucket,
  setConnectPage,
}: {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
  setConnectPage: (value: SegmentedValue) => void;
}) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [loading, setLoading] = useState("");
  const [jdbcString, setJdbcString] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [crawlerData, setCrawlerData] = useState<IAthenaObject>(initialCrawler);

  const { data, isLoading, token } = useFetch<IAthenaObject>(
    `${bucketUrl}v2/s3buckets/${bucket.name}/athena`,
    msalInstance,
  );

  const getAthenaConfig = async () => {
    setLoading("crawler");

    const res: {
      data: IAthenaObject | null;
      error: string | null;
    } = await getAthenaObject(bucket.name, msalInstance);

    if (res.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (res.error) {
      message.error({
        content: res.error,
        duration: 2,
      });
    }

    if (res.data) {
      message.success({
        content: "Crawler status is updated",
        duration: 2,
      });
      setCrawlerData(res.data);
    }
    setLoading("");
  };

  useEffect(() => {
    if (token === "token expired") {
      showAuthorizationErrorModal();
    }

    if (bucket.params.athena.enable && data) {
      if (!data.crawler_config) {
        getAthenaConfig();
        return;
      }

      setCrawlerData(data);
    }
  }, [bucket.params.athena.enable, data, showAuthorizationErrorModal, token]);

  const labelStyle = useMemo(
    () => ({
      width: 170,
      color: "#8c8c8c",
    }),
    [],
  );

  const getJdbcConnectionString = useCallback(async () => {
    setLoading("jdbc");

    const result: {
      data: string | null;
      error: null | string;
    } = await getJdbcString(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      setJdbcString(result.data);
    }

    if (result.error) {
      message.error({
        content: "Something wrong",
        duration: 2,
      });
    }

    setLoading("");
  }, [bucket.name, msalInstance, showAuthorizationErrorModal]);

  const handleTriggerCrawler = async () => {
    setLoading("crawler");

    const res = await toggleCrawler(bucket.name, msalInstance);

    if (res.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (res.error) {
      message.error({
        content: res.error,
        duration: 2,
      });
    }

    if (res.data) {
      message.success({
        content: res.data,
        duration: 2,
      });
    }

    setLoading("");
  };

  const switchAthena = async (checked: boolean) => {
    setLoading("athena");

    const action = checked ? enableAthena : disableAthena;

    const result: {
      data: string | null;
      error: null | string;
    } = await action(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      await onFetchBucket();

      await getAthenaConfig();

      message.success({
        content: "Athena has been changed",
        duration: 2,
      });
    }

    if (result.error) {
      await onFetchBucket();

      message.error({
        content: "Something went wrong. Try reloading",
        duration: 2,
      });
    }

    setLoading("");
  };

  const switchDetailsModal = () => setOpenDetails(!openDetails);

  const formatAthenaDateTime = useMemo(() => {
    let time = "";

    if (crawlerData.crawler_config?.LastUpdated) {
      time = crawlerData.crawler_config.LastUpdated;
    }

    const dtFormat = new Intl.DateTimeFormat("en-GB", {
      dateStyle: "medium",
      timeStyle: "medium",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
    });

    return time ? dtFormat.format(new Date(time)) : null;
  }, [crawlerData]);

  return (
    <>
      <Modal
        title="Crawler status details"
        open={openDetails}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={switchDetailsModal}
      >
        <pre>{JSON.stringify(crawlerData, null, 2)}</pre>
      </Modal>
      <Card
        title={
          <>
            <span>Athena properties</span>
            {bucket.params.athena.enable ? (
              <span className="athena-properties-time">
                {crawlerData.crawler_config?.LastUpdated ? (
                  <>last updated {formatAthenaDateTime}</>
                ) : null}
              </span>
            ) : null}
          </>
        }
        className="athena-properties"
        bodyStyle={{
          display: !bucket.params.athena.enable ? "none" : "block",
        }}
        extra={
          <div className="athena-properties-extra">
            {bucket.params.athena.enable ? (
              <span>Athena is enabled</span>
            ) : (
              <span>Athena is disabled</span>
            )}
            <Switch
              checked={bucket.params.athena.enable}
              loading={loading === "athena"}
              onChange={switchAthena}
              disabled={
                bucket.management_permissions.can_toggle_athena ? false : true
              }
            />
          </div>
        }
      >
        {isLoading || !crawlerData?.crawler ? (
          <Spinner />
        ) : (
          <Row>
            <Descriptions column={2}>
              <Descriptions.Item labelStyle={labelStyle} label="Crawler">
                {crawlerData?.crawler ? (
                  <>
                    {crawlerData?.crawler}
                    <CopyOutlined
                      className="copy-icon"
                      onClick={handleCopyText("Crawler", crawlerData.crawler)}
                    />
                  </>
                ) : (
                  "-"
                )}
                <Button
                  type="primary"
                  loading={loading === "crawler"}
                  size="small"
                  onClick={handleTriggerCrawler}
                  className="crawler-btn"
                  icon={<CaretRightOutlined />}
                >
                  Trigger crawler
                </Button>
              </Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Database">
                {crawlerData?.database ? (
                  <>
                    {crawlerData?.database}
                    <CopyOutlined
                      className="copy-icon"
                      onClick={handleCopyText("Database", crawlerData.database)}
                    />
                  </>
                ) : (
                  "-"
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={2}>
              <Descriptions.Item labelStyle={labelStyle} label="Crawler status">
                {crawlerData?.crawler_config?.State === "READY" ? (
                  <span className="state crawler-ready">Ready</span>
                ) : crawlerData?.crawler_config?.State === "RUNNING" ? (
                  <span className="state crawler-running">Running</span>
                ) : (
                  <span className="state crawler-stoping">Stoping</span>
                )}
                <Button onClick={switchDetailsModal} type="link" size="small">
                  Show details
                </Button>
                <Button
                  onClick={getAthenaConfig}
                  type="link"
                  icon={<UndoOutlined />}
                  loading={loading === "crawler"}
                  className="get-athena-config"
                  size="small"
                >
                  Refresh crawler status
                </Button>
              </Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Workgroup">
                {crawlerData?.workgroup ? (
                  <>
                    {crawlerData?.workgroup}
                    <CopyOutlined
                      className="copy-icon"
                      onClick={handleCopyText(
                        "Workgroup",
                        crawlerData.workgroup,
                      )}
                    />
                  </>
                ) : (
                  "-"
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={1}>
              {crawlerData?.tables?.length ? (
                <Descriptions.Item labelStyle={labelStyle} label="Tables">
                  <ul className="crawler-table">
                    {crawlerData.tables.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <Descriptions column={1}>
              <Descriptions.Item
                labelStyle={labelStyle}
                label="JDBC Connection"
              >
                {jdbcString ? (
                  <>
                    ************************************************************************************************************
                    <CopyOutlined
                      className="copy-icon"
                      onClick={handleCopyText("JDBC Connection", jdbcString)}
                    />
                  </>
                ) : (
                  <Button
                    loading={loading === "jdbc"}
                    type="primary"
                    onClick={getJdbcConnectionString}
                  >
                    Get string
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        )}
      </Card>
      <About />
      <GetAccess
        bucket={{
          ...bucket,
          params: {
            ...bucket.params,
            athena: {
              ...bucket.params.athena,
              database: data?.database,
              workgroup: data?.workgroup,
            },
          },
        }}
        setConnectPage={setConnectPage}
      />
    </>
  );
};

export default memo(Athena);
