import React, { memo, useEffect } from "react";
import { Typography, Select, Row, Col } from "antd";
import "./style.scss";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "config/token";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Spinner from "common/Spinner";
import { NEXUS_API_BASE_URL } from "const";
import { useRegions } from "hooks/requests/useRegions";
import Label from "common/Label";

const { Title } = Typography;

type DomainType = {
    domain_id: string,
    domain_name: string,
    region_: string,
    auth_url: string
};

const AnalyticsZone: React.FC = () => {

    const [loader, setLoader] = React.useState(false);
    const [selectedRegion, setSelectedRegion] = React.useState("us-east-1");
    const { regions, isLoading } = useRegions();
    const [domains, setDomains] = React.useState<DomainType[]>([]);
    const msalInstance = useMsal();

    const fetchData = async () => {
        setLoader(true);
        const token = await getAccessToken(msalInstance);

        let authSession = sessionStorage.getItem("msal.token.keys." + process.env.REACT_APP_AUTH_CLIENT_ID);
        authSession = (authSession == null) ? "" : authSession;
        const authSessionObj = JSON.parse(authSession);
        const accessTokenKey = authSessionObj.accessToken[0];
        let accessTokenObj = sessionStorage.getItem(accessTokenKey);
        accessTokenObj = (accessTokenObj == null) ? "" : accessTokenObj;
        const accessToken = JSON.parse(accessTokenObj);

        const bearerToken = "Bearer " + (token == undefined ? "" : token.token);
        const access_token = accessToken.secret;
        const requestURL = NEXUS_API_BASE_URL + "/ml-service/default";

        const config = {
            headers: { "Authorization": bearerToken },
        };
        
        const bodyParameters = {
            "at": access_token,
            "region": selectedRegion,
        };

        axios
        .post(
            requestURL,
            bodyParameters,
            config,
        ).then((res) => {
            if (res.data != undefined && res.data != null) {
                setDomains(res.data);
                setLoader(false);
            }
        }).catch(function () {
            setLoader(false);
        });
     };

    useEffect(() => {
         fetchData();
    }, [selectedRegion]);


    const handleRegionChange = (value: string) => { setSelectedRegion(value); };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        ...theme.applyStyles("dark", {
          backgroundColor: "#1A2027",
        }),
      }));

    return (
        (loader ? 
            <Spinner
                size="default"
                backgroundStyle={{
                position: "absolute",
                top: "calc(50% + 24px)",
                left: "calc(50% - 10px)",
                }}
            /> :
            <div className="main1">
                <Title level={1} className="main-title">
                    Welcome to the Gateway to your ML Development Environment
                </Title>
                <div className="main-subtitle">
                    <div>Your account has <b>multiple SageMaker domains</b></div> 
                    <div>Choose the one you want to connect to:</div>
                </div>

                <div className="main-descr">
                <Row style={{ marginBottom: "25px", marginTop: "15px" }}>
                    <Col span={8} style={{ textAlign: "right" }}>
                        &nbsp;
                    </Col>
                    <Col span={8}>
                        <label className="blink_me">Choose Region:</label>
                        <Select size={"large"}
                            value={selectedRegion}
                            onChange={ handleRegionChange }
                        >
                            {regions.map(region => (
                                <Select.Option key={region} value={region}>
                                    {region}
                                </Select.Option>
                                ))}
                        </Select>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                        &nbsp;
                    </Col>
                </Row>
                    <Box sx={{ width: "90%" }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center" justifyContent="center">

                        {domains.length == 0 ? <label className="blink_me" style={ { color : "red" } } >No SageMaker Domains found for the selected region '{selectedRegion}'</label> : ""}

                        {domains.map((d, index) => (
                            <Grid key={index} size={{ xs: 2, sm: 4, md: 4 }}>
                                    <Item>
                                    <div>
                                        <p>
                                            <a href={d.auth_url == "" ? "https://studio-" + d.domain_id + ".studio." + d.region_ + ".sagemaker.aws/" : d.auth_url} style={ { fontFamily: "Poppins" } } target="_blank" rel="noreferrer">
                                                {d.domain_name}
                                            </a>
                                        </p>
                                        <p>
                                            <div style={ { textAlign: "center" } }>
                                                <img style={ { width:"80px" } } src="https://www.ibexlabs.com/wp-content/uploads/2024/09/62d1345ba688202d5bfa6776_aws-sagemaker-eyecatch-e1614129391121.png" />
                                            </div>
                                            <div style={ { textAlign: "center", color: "#002859", fontSize: "16px", fontWeight: "bolder", fontFamily: "Poppins" } }>
                                                <label>Amazon SageMaker Studio</label>
                                            </div>
                                        </p>
                                    </div>
                                </Item>
                            </Grid>
                        ))}
                        </Grid>
                    </Box>
                </div>
            </div>
        )
    );
};

export default memo(AnalyticsZone);